<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Sosyal Ağ"
            label-for="id_socialmedia_networks"
          >
            <v-select
              id="id_socialmedia_networks"
              v-model="itemData.id_socialmedia_networks"
              :options="networks"
              :reduce="item => item.id"
              label="title"
              placeholder="Seçiniz"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Bağlantı"
            label-for="url"
          >
            <b-form-input
              id="url"
              v-model="itemData.url"
              placeholder="Bağlantı"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>

    <b-button
      variant="primary"
      :disabled="submitStatus.status"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </div>
</template>
<script>
import {
  BButton, BCard, BCol, BFormGroup, BFormInput, BRow,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'ContactForm',
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
  },
  props: {
    submitForm: {
      type: Function,
      required: true,
    },
  },
  computed: {
    itemData() {
      return this.$store.getters['socialMedia/dataItem']
    },
    networks() {
      return this.$store.getters['socialMedia/dataNetworks']
    },
    submitStatus() {
      return this.$store.getters['socialMedia/submitStatus']
    },
  },
  created() {
    this.getNetworks()
  },
  methods: {
    getNetworks() {
      this.$store.dispatch('socialMedia/getDataNetworks')
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
